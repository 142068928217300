import { useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import {
  type DtoBlockDoc,
  type EnumsBrandPredefinedBlockScenario,
} from '@lp-lib/api-service-client/public';
import { type Block, type BlockType } from '@lp-lib/game';
import { fromAPIBlockTypes } from '@lp-lib/game/src/api-integration';

import { apiService } from '../../services/api-service';
import { BlockChoiceMap } from '../Game/Blocks/Shared';
import { BlockSelect } from '../RoutedBlock/BlockSelect';
import { BlockTypeSelect } from '../RoutedBlock/BlockTypeSelect';
import { type BrandFormData } from './BrandEditor';

export interface BrandEditorAttachBlockPanelProps {
  brandId?: string;
  scenario?: EnumsBrandPredefinedBlockScenario | null;
  preselectedBlockType: BlockType | null;
  onSelected: (block: Block) => void;
}

export function BrandEditorAttachBlockPanel(
  props: BrandEditorAttachBlockPanelProps
) {
  const { brandId, preselectedBlockType, onSelected, scenario } = props;

  const { watch } = useFormContext<BrandFormData>();
  const { otherBlocks, predefinedBlocks } = watch();

  const existingBlocks = useMemo(
    () => [...otherBlocks, ...predefinedBlocks.map((item) => item.block)],
    [otherBlocks, predefinedBlocks]
  );

  const [blockType, setBlockType] = useState<BlockType | null>(
    preselectedBlockType
  );

  const handleSelect = async (doc: DtoBlockDoc | null) => {
    if (!doc) return;
    const resp = await apiService.block.getBlock(doc.block.id);
    onSelected(fromAPIBlockTypes(resp.data.block));
  };

  const handleCreate = async (blockType: BlockType) => {
    const resp = await apiService.block.createStandaloneBlock({
      type: blockType,
      scenario,
    });
    onSelected(resp.data.block);
  };

  const filterBlock = (doc: DtoBlockDoc) =>
    !existingBlocks.find((b) => b.id === doc.block.id);

  return (
    <div className='w-full h-full bg-main-layer p-5'>
      {!preselectedBlockType && (
        <BlockTypeSelect
          value={blockType}
          onChange={setBlockType}
          className='w-66 h-10'
        />
      )}

      {blockType && (
        <div>
          <p className='text-base font-bold mb-2 mt-5'>
            Select {BlockChoiceMap[blockType].primary} Block
          </p>
          <BlockSelect
            value={null}
            params={{
              type: blockType,
              // the comma is required here so the orphan blocks are also included
              brandIds: brandId ? `,${brandId}` : '',
            }}
            onChange={handleSelect}
            filterBlock={filterBlock}
            className='w-full h-12'
          />
          <p className='text-base font-medium mt-1'>
            Or{' '}
            <button
              type='button'
              onClick={() => handleCreate(blockType)}
              className='btn text-primary'
            >
              Create New {BlockChoiceMap[blockType].primary} Block
            </button>
          </p>
        </div>
      )}
    </div>
  );
}
