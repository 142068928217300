import { useEffect } from 'react';
import { useSnapshot } from 'valtio';

import { type EnumsBrandPredefinedBlockScenario } from '@lp-lib/api-service-client/public';
import { type Block } from '@lp-lib/game';
import { fromAPIBlockTypes } from '@lp-lib/game/src/api-integration';

import { useInstance } from '../../hooks/useInstance';
import { useLiveCallback } from '../../hooks/useLiveCallback';
import { apiService } from '../../services/api-service';
import {
  ConfirmCancelModalHeading,
  ConfirmCancelModalText,
  useAwaitFullScreenConfirmCancelModal,
} from '../ConfirmCancelModalContext';
import {
  BlockEditor,
  useOndRecordingDetails,
} from '../Game/Blocks/Common/Editor';
import { GameUtils } from '../Game/GameUtils';
import { BlockEditorStore, BlockEditorStoreProvider } from '../RoutedBlock';

export interface BrandEditorBlockDetailProps {
  block: Block;
  blockScenario?: EnumsBrandPredefinedBlockScenario | null;
  onChange: (block: Block) => void;
  onDetach: () => void;
  isBlockChanging: boolean;
  setIsBlockChanging: (isBlockChanging: boolean) => void;
}

export function BrandEditorBlockDetail(props: BrandEditorBlockDetailProps) {
  const triggerModal = useAwaitFullScreenConfirmCancelModal();
  const onChange = useLiveCallback(props.onChange);

  const store = useInstance(() => new BlockEditorStore());

  useEffect(() => {
    store.setBlocks(props.block);
  }, [props.block, store]);

  const block = useSnapshot(store.state).blocks.find(
    (b) => b.id === props.block.id
  ) as Block | undefined;

  const asyncDetails = useOndRecordingDetails(
    block ?? null,
    GameUtils.DeriveOndPlaybackVersionFromBlocks(block ? [block] : null),
    async () => {
      const { data } = await apiService.block.getBlock(props.block.id);
      if (data?.block) {
        const updated = fromAPIBlockTypes(data.block);
        onChange(updated);
        store.setBlocks(updated);
      }
    }
  );

  useEffect(() => {
    if (!block) return;
    if (block === props.block) return;

    onChange(block);
  }, [block, onChange, props.block]);

  if (!block) return null;

  const handleDetach = async () => {
    const response = await triggerModal({
      kind: 'confirm-cancel',
      prompt: (
        <div className='px-5 py-2'>
          <ConfirmCancelModalHeading>
            {`Detach Block`}
          </ConfirmCancelModalHeading>
          <ConfirmCancelModalText className='mt-4 text-sms font-normal'>
            Are you sure you want to detach this block? This will remove it from
            this brand, but not delete it.
          </ConfirmCancelModalText>
        </div>
      ),
      confirmBtnLabel: 'Detach',
      confirmBtnVariant: 'delete',
    });
    if (response.result !== 'confirmed') return;

    props.onDetach();
  };

  return (
    <div className='w-full h-full flex flex-col'>
      <div className='w-full flex items-center justify-end gap-3 py-1 text-base font-medium'>
        {asyncDetails.detailsLink}
        <button
          type='button'
          onClick={handleDetach}
          className='btn text-red-002 self-end mb-1 mr-2'
        >
          Detach Block
        </button>
      </div>

      <BlockEditorStoreProvider store={store}>
        <BlockEditor
          block={block}
          setSavingChanges={props.setIsBlockChanging}
          blockScenario={props.blockScenario}
        />
      </BlockEditorStoreProvider>

      {asyncDetails.detailsModal}
      {asyncDetails.warningModal}
    </div>
  );
}
