import pluralize from 'pluralize';
import { useEffect, useState } from 'react';
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
  useFormState,
} from 'react-hook-form';
import { match } from 'ts-pattern';

import {
  type EnumsBrandPredefinedBlockScenario,
  EnumsMediaScene,
} from '@lp-lib/api-service-client/public';
import { type Block } from '@lp-lib/game';
import { type Media, type MediaData } from '@lp-lib/media';

import { useLiveAsyncCall } from '../../hooks/useAsyncCall';
import { useLiveCallback } from '../../hooks/useLiveCallback';
import { type Tag, TagUtils } from '../../types';
import { err2s } from '../../utils/common';
import { CollapsibleSection } from '../common/CollapsibleSection';
import {
  ConfirmCancelModalHeading,
  ConfirmCancelModalText,
  useAwaitFullScreenConfirmCancelModal,
} from '../ConfirmCancelModalContext';
import { FilledCheckIcon } from '../icons/CheckIcon';
import { LPLogo } from '../icons/LPLogo';
import { MiniMediaEditor } from '../MediaUploader/MiniMediaEditor';
import { type TagOption, TagPicker } from '../Tagging/TagPicker';
import { BrandEditorAttachBlockPanel } from './BrandEditorAttachBlock';
import { BrandEditorBlockDetail } from './BrandEditorBlockDetail';
import { BrandEditorOtherBlocks } from './BrandEditorOtherBlocks';
import { BrandEditorPredefinedBlocks } from './BrandEditorPredefinedBlocks';
import { BrandUtils } from './utils';

export type BrandEditorAction = 'create' | 'edit';

function SaveIndicator() {
  const { isSubmitSuccessful, isDirty } = useFormState<BrandFormData>();
  const [wasSubmitSuccessful, setWasSubmitSuccessful] = useState(false);
  useEffect(() => {
    if (isSubmitSuccessful) {
      setWasSubmitSuccessful(true);
    }
  }, [isSubmitSuccessful]);
  useEffect(() => {
    if (isDirty) {
      setWasSubmitSuccessful(false);
    }
  }, [isDirty]);
  return (
    <p className='text-icon-gray text-sms'>
      {wasSubmitSuccessful && !isDirty && (
        <div className='flex items-center gap-1'>
          <FilledCheckIcon />
          Saved
        </div>
      )}
    </p>
  );
}

function Header(props: {
  action: BrandEditorAction;
  onCancel: () => void;
  onSave: (data: BrandFormData) => Promise<void>;
  isBlockChanging: boolean;
}) {
  const { action, onCancel, onSave, isBlockChanging } = props;

  const {
    call: submit,
    state: { state, error },
    reset,
  } = useLiveAsyncCall(onSave);

  const {
    handleSubmit,
    reset: resetForm,
    formState: { isDirty },
  } = useFormContext<BrandFormData>();

  const onSubmit = useLiveCallback(() => {
    handleSubmit(async (data: BrandFormData) => {
      await submit(data);
      resetForm(data);
    })();
  });

  const triggerModal = useAwaitFullScreenConfirmCancelModal();
  const handleCancel = useLiveCallback(async () => {
    if (!isDirty) {
      onCancel();
      return;
    }

    const { result } = await triggerModal({
      kind: 'confirm-cancel',
      prompt: (
        <div className='px-5 py-2'>
          <ConfirmCancelModalHeading>Are you sure?</ConfirmCancelModalHeading>
          <ConfirmCancelModalText className='mt-4 text-sms font-normal'>
            You have unsaved changes. Are you sure you want to discard them?
          </ConfirmCancelModalText>
        </div>
      ),
      cancelBtnLabel: 'Keep editing',
      confirmBtnLabel: 'Discard changes',
      confirmBtnVariant: 'delete',
    });
    if (result === 'confirmed') {
      onCancel();
    }
  });

  const title = match(action)
    .with('create', () => 'Create Brand')
    .with('edit', () => 'Edit Brand')
    .exhaustive();

  return (
    <header className='w-full flex-shrink-0'>
      <div className='w-full h-20 px-5 bg-admin-red border-secondary border-b flex items-center justify-between'>
        <div className='flex items-center gap-4'>
          <LPLogo type='admin' />
          <div className='text-1.5xl font-medium text-white'>{title}</div>
        </div>
        <div className='flex items-center gap-5'>
          <p className='text-secondary text-sms'>
            {isBlockChanging && 'Saving block...'}
          </p>

          <SaveIndicator />

          <button
            type='button'
            disabled={isBlockChanging}
            onClick={handleCancel}
            className='btn-secondary w-33 h-10'
          >
            {isDirty ? 'Cancel' : 'Close'}
          </button>
          <button
            type='button'
            onClick={onSubmit}
            disabled={!isDirty || state.isRunning || isBlockChanging}
            className='btn-primary w-33 h-10'
          >
            {state.isRunning ? 'Saving...' : 'Save'}
          </button>
        </div>
      </div>

      {error && (
        <div className='w-full px-5 py-2 text-base flex gap-1 bg-lp-gray-001'>
          <p className='text-red-005'>Error: {err2s(error)}</p>
          <button
            type='button'
            onClick={() => reset()}
            className='btn self-end text-primary'
          >
            Clear
          </button>
        </div>
      )}
    </header>
  );
}

function Basic() {
  const {
    formState: { errors },
    register,
  } = useFormContext<BrandFormData>();

  return (
    <div className='w-full'>
      <label>
        <h2 className='text-base font-bold mb-1'>
          Brand Name <span className='text-primary'>(Required)</span>
        </h2>
        <input
          className={`w-full h-12.5 ${
            errors.name ? 'field-error' : 'field'
          } mb-0`}
          maxLength={50}
          placeholder='Must be 1 to 50 characters'
          {...register<'name'>('name', {
            required: true,
            maxLength: 50,
          })}
        ></input>
        {errors.name && (
          <div className='w-full px-2 pt-1 text-left text-red-005 text-3xs h-3'>
            Must be 1 to 50 characters
          </div>
        )}
      </label>
    </div>
  );
}

const formatTagOptionMeta = (option: TagOption): string => {
  const count = option.__isNew__ ? 0 : TagUtils.getBrandsCount(option);
  if (option.__isNew__) {
    return '(Create New)';
  }
  return `(${count} ${pluralize('Brand', count)})`;
};

function Details() {
  const {
    formState: { errors },
    register,
    control,
  } = useFormContext<BrandFormData>();

  return (
    <CollapsibleSection title='Brand Details'>
      <div>
        <h2 className='text-sm font-bold mb-1'>Brand Showcase Media</h2>

        <div className='w-25'>
          <Controller
            name='showcaseMedia'
            render={({ field: { onChange, value } }) => (
              <MiniMediaEditor
                id='brand-editor'
                video={true}
                scene={EnumsMediaScene.MediaSceneBrandShowcase}
                objectFit='cover'
                mediaData={value.data}
                media={value.media}
                onChange={(data, media) =>
                  onChange({
                    data,
                    media,
                  })
                }
              />
            )}
          />
        </div>
      </div>

      <label>
        <h2 className='text-sm font-bold mb-1'>Brand Showcase Text</h2>
        <textarea
          className={`w-full h-30 py-2 scrollbar ${
            errors.showcaseText ? 'field-error' : 'field'
          } mb-0`}
          maxLength={100}
          placeholder='Max 100 characters'
          {...register('showcaseText', {
            maxLength: 100,
          })}
        ></textarea>
        {errors.showcaseText && (
          <div className='w-full px-2 pt-1 text-left text-red-005 text-3xs h-3'>
            Max 100 characters
          </div>
        )}
      </label>

      <label>
        <h2 className='text-sm font-bold mb-1'>Categories</h2>

        <Controller
          control={control}
          name='tags'
          render={({ field: { onChange, value } }) => (
            <TagPicker
              onChange={onChange}
              tags={value}
              creatable
              multi={true}
              placeholder='Select or Add Categories'
              formatMeta={formatTagOptionMeta}
            />
          )}
        />
      </label>
    </CollapsibleSection>
  );
}

export type BrandEditorMenu =
  | {
      kind: 'attach-block';
      scenario: EnumsBrandPredefinedBlockScenario | null;
    }
  | {
      kind: 'block-detail';
      block: Block;
      scenario?: EnumsBrandPredefinedBlockScenario | null;
    }
  | null;

export interface BrandFormData {
  name: string;
  showcaseMedia: {
    media: Media | null;
    data: MediaData | null;
  };
  showcaseText: string;
  blockLengthSec: number;

  tags: Tag[];
  predefinedBlocks: {
    scenario: EnumsBrandPredefinedBlockScenario;
    block: Block;
  }[];
  otherBlocks: Block[];
}

export function BrandEditor(props: {
  brandId?: string;
  defaultValues?: BrandFormData;
  action: BrandEditorAction;
  onConfirm: (data: BrandFormData) => Promise<void>;
  onCancel: () => void;
}): JSX.Element {
  const { brandId, defaultValues, action, onConfirm, onCancel } = props;

  const formReturned = useForm<BrandFormData>({
    defaultValues,
  });

  const [menu, setMenu] = useState<BrandEditorMenu>(null);
  const [isBlockChanging, setIsBlockChanging] = useState(false);

  const handleAttachBlock = useLiveCallback(
    (scenario: EnumsBrandPredefinedBlockScenario | null, block: Block) => {
      if (scenario) {
        const curr =
          formReturned.getValues<'predefinedBlocks'>('predefinedBlocks');
        formReturned.setValue<'predefinedBlocks'>(
          'predefinedBlocks',
          [...curr, { scenario, block }],
          {
            shouldDirty: true,
          }
        );
      } else {
        const curr = formReturned.getValues<'otherBlocks'>('otherBlocks');
        formReturned.setValue<'otherBlocks'>('otherBlocks', [...curr, block], {
          shouldDirty: true,
        });
      }

      setMenu({
        kind: 'block-detail',
        scenario,
        block,
      });
    }
  );

  const handleDetachBlock = useLiveCallback((block: Block) => {
    const predefinedBlocks =
      formReturned.getValues<'predefinedBlocks'>('predefinedBlocks');

    const index = predefinedBlocks.findIndex((b) => b.block.id === block.id);
    if (index !== -1) {
      const scenario = predefinedBlocks[index].scenario;
      predefinedBlocks.splice(index, 1);
      formReturned.setValue<'predefinedBlocks'>(
        'predefinedBlocks',
        predefinedBlocks,
        {
          shouldDirty: true,
        }
      );
      setMenu({
        kind: 'attach-block',
        scenario,
      });
      return;
    }

    const otherBlocks = formReturned.getValues<'otherBlocks'>('otherBlocks');
    const index2 = otherBlocks.findIndex((b) => b.id === block.id);
    if (index2 !== -1) {
      otherBlocks.splice(index2, 1);
      formReturned.setValue<'otherBlocks'>('otherBlocks', otherBlocks, {
        shouldDirty: true,
      });
    }

    setMenu(null);
  });

  const handleChangeBlock = useLiveCallback((block: Block) => {
    const predefinedBlocks =
      formReturned.getValues<'predefinedBlocks'>('predefinedBlocks');

    const index = predefinedBlocks.findIndex((b) => b.block.id === block.id);
    if (index !== -1) {
      const next = [...predefinedBlocks];
      next[index] = { ...next[index], block };
      formReturned.setValue(`predefinedBlocks`, next, {
        shouldValidate: true,
      });
    }

    const otherBlocks = formReturned.getValues<'otherBlocks'>('otherBlocks');
    const index2 = otherBlocks.findIndex((b) => b.id === block.id);

    if (index2 !== -1) {
      const next = [...otherBlocks];
      next[index2] = block;
      formReturned.setValue(`otherBlocks`, next, {
        shouldValidate: true,
      });
    }
  });

  const handleMoveOtherBlocks = useLiveCallback((from: number, to: number) => {
    const curr = formReturned.getValues<'otherBlocks'>('otherBlocks');
    const next = [...curr];
    const [removed] = next.splice(from, 1);
    next.splice(to, 0, removed);
    formReturned.setValue<'otherBlocks'>('otherBlocks', next, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });
  });

  return (
    <FormProvider {...formReturned}>
      <div className='w-full h-full text-white flex flex-col'>
        <Header
          action={action}
          onCancel={onCancel}
          onSave={onConfirm}
          isBlockChanging={isBlockChanging}
        />

        <div className='w-full flex-1 flex overflow-auto scrollbar'>
          <form className='min-w-70 w-1/4 h-full overflow-auto scrollbar bg-modal px-2 py-4 flex flex-col gap-8'>
            <Basic />
            <Details />
            <Controller<BrandFormData, 'predefinedBlocks'>
              name='predefinedBlocks'
              render={({ field: { value } }) => (
                <BrandEditorPredefinedBlocks
                  predefinedBlocks={value}
                  menu={menu}
                  setMenu={setMenu}
                  isBlockChanging={isBlockChanging}
                />
              )}
            />
            <Controller<BrandFormData, 'otherBlocks'>
              name='otherBlocks'
              render={({ field: { value } }) => (
                <BrandEditorOtherBlocks
                  blocks={value}
                  onMove={handleMoveOtherBlocks}
                  menu={menu}
                  setMenu={setMenu}
                  isBlockChanging={isBlockChanging}
                />
              )}
            />
          </form>
          <div className='flex-1 h-full overflow-auto scrollbar bg-black p-5'>
            {menu?.kind === 'attach-block' && (
              <BrandEditorAttachBlockPanel
                key={menu.scenario}
                scenario={menu.scenario}
                brandId={brandId}
                preselectedBlockType={BrandUtils.PredefinedBlockType(
                  menu.scenario
                )}
                onSelected={(block) => handleAttachBlock(menu.scenario, block)}
              />
            )}

            {menu?.kind === 'block-detail' && (
              <BrandEditorBlockDetail
                key={menu.block.id}
                block={menu.block}
                blockScenario={menu.scenario}
                onDetach={() => handleDetachBlock(menu.block)}
                onChange={(block) => handleChangeBlock(block)}
                isBlockChanging={isBlockChanging}
                setIsBlockChanging={setIsBlockChanging}
              />
            )}
          </div>
        </div>
      </div>
    </FormProvider>
  );
}
