import { type Block } from '@lp-lib/game';

import { CollapsibleSection } from '../common/CollapsibleSection';
import { DragDropList } from '../common/DragDrop';
import { BlockCard } from '../Game/GameBlockEditor';
import { PlusIcon } from '../icons/PlusIcon';
import { type BrandEditorMenu } from './BrandEditor';

export function BrandEditorOtherBlocks(props: {
  blocks: Block[];
  onMove: (from: number, to: number) => void;
  menu: BrandEditorMenu;
  setMenu: (menu: BrandEditorMenu) => void;
  isBlockChanging: boolean;
}) {
  const { blocks, onMove, menu, setMenu, isBlockChanging } = props;

  const handleAddBlock = () => {
    setMenu({
      kind: 'attach-block',
      scenario: null,
    });
  };

  const handleClick = (block: Block) => {
    setMenu({
      kind: 'block-detail',
      block,
    });
  };

  return (
    <CollapsibleSection
      title='Blocks'
      disabled={blocks.length === 0}
      right={
        <button
          type='button'
          disabled={isBlockChanging}
          onClick={handleAddBlock}
          className='btn-primary w-6 h-6 rounded flex justify-center items-center'
        >
          <PlusIcon />
        </button>
      }
    >
      <DragDropList
        type='brand-editor-other-blocks'
        items={blocks}
        onMove={onMove}
        render={({ item: block, index, drag, ref, style }) => (
          <div className='w-full' ref={ref} style={style}>
            <div className='w-full' ref={drag}>
              <BlockCard
                key={block.id}
                index={index}
                block={block}
                isSelected={
                  menu?.kind === 'block-detail' && menu.block.id === block.id
                }
                isSelectable={!isBlockChanging}
                isPlaying={false}
                isPlayed={false}
                isDisabled={false}
                recordingVersionMismatch={false}
                onSelect={() => handleClick(block)}
              />
            </div>
          </div>
        )}
      />
    </CollapsibleSection>
  );
}
